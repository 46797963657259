import Choices from 'choices.js';
import FormRange from '../form/form-range';
import Pagination from '../pagination/pagination';

class InvestmentsFilters {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.investmentSelect = this.root.querySelector(
            '.js-filter-investment-select'
        );
        this.rangeSliders = this.root.querySelectorAll('[data-form-range]');
        this.selects = this.root.querySelectorAll('.js-investments-select');

        this.moreButton = this.root.querySelector('[investments-filters-more]');
        this.moreContent = this.root.querySelector(
            '[investments-filters-more-content]'
        );

        this.formEl = this.root.querySelector('[investments-filters-form]');
        this.loaderEl = this.root.querySelector('[investments-filters-loader]');
        this.submitEl = this.root.querySelector('[investments-filters-submit]');
        this.clearEl = this.root.querySelector('[investments-filters-clear]');
        this.listEl = this.root.querySelector('[investments-filters-list]');
        this.listViewEl = this.root.querySelectorAll('input[name="list_view"]');
        this.avSelectEl = this.root.querySelector(
            'select[name="offer-availability"]'
        );
        this.redirectUrlEl = this.root.querySelector(
            'input[name="redirect_url"]'
        );

        this.listViewVal = 'default';
        this.siteURL = this.root.querySelector('input[name="site_url"]').value;
        this.settings = JSON.parse(
            this.root.getAttribute('investments-filters-settings')
        );
        this.model3dUrl = this.settings.model3dUrl;
        this.model3dId = this.settings.model3dId;

        this.paginationEl = this.root.querySelector(
            '[investments-filters-pagination]'
        );
        this.pagination = this.root.querySelector('[data-js-pagination]');
        this.paginationInstance = null;
        this.investmentSelectInstance = null;
        this.selectsInstances = [];
        this.rangesInstances = [];

        this.model3dEl = this.root.querySelector('[investments-filters-3d]');
        this.model3dEstate = null;

        this.currentPage = 1;
        this.maxPagesInput = this.root.querySelector('input[name="max_pages"]');
        if (this.maxPagesInput) {
            this.maxPages = parseInt(this.maxPagesInput.value);
        } else {
            this.maxPages = 12;
        }

        return true;
    }

    init() {
        console.log(this.model3dId);
        if (this.model3dId && this.model3dId !== '') {
            this.set3dModel();
            this.initOn3dload();
        } else {
            this.setInvestmentSelect();
            this.setRangeSliders();
            this.setMoreButton();
            this.setSelects();
            this.setSubmitButton();
            this.setPagination();
            this.setClearButton();
            this.setAvSelect();
            this.setListView();
        }
    }

    initOn3dload() {
        window.addEventListener('3destateloaded', event => {
            this.model3dEstate = event.detail;

            this.setInvestmentSelect();
            this.setRangeSliders();
            this.setMoreButton();
            this.setSelects();
            this.setSubmitButton();
            this.setPagination();
            this.setClearButton();
            this.setAvSelect();
            this.setListView();

            if (this.listViewEl) {
                const clickEv = new Event('click');
                const changeEv = new Event('change');

                this.listViewEl[0].checked = true;
                this.listViewEl[0].dispatchEvent(changeEv);
                this.listViewEl[0].dispatchEvent(clickEv);
            }
        });
    }

    setInvestmentSelect() {
        if (!this.investmentSelect) return;

        this.investmentSelectInstance = new Choices(this.investmentSelect, {
            placeholder: true,
            searchEnabled: false,
            loadingText: '',
            itemSelectText: '',
            allowHTML: true,
            removeItems: true,
            removeItemButton: true,
            editItems: true,
            noChoicesText: 'Brak opcji',
            classNames: {
                containerOuter: 'choices form-select',
            },
        });
    }

    setRangeSliders() {
        if (!this.rangeSliders) return;
        this.rangeSliders.forEach(item =>
            this.rangesInstances.push(new FormRange(item))
        );
    }

    setMoreButton() {
        if (!this.moreButton) return;

        this.onMoreClick = this.onMoreButtonClick.bind(this);
        this.moreButton.addEventListener('click', this.onMoreClick);
    }

    setSubmitButton() {
        if (!this.submitEl) return;
        this.submitEl.addEventListener('click', this.loadPremises.bind(this));
    }

    setSelects() {
        if (!this.selects) return;

        this.selects.forEach(item => {
            const selectInstance = new Choices(item, {
                placeholder: true,
                placeholderValue: null,
                searchEnabled: false,
                loadingText: '',
                itemSelectText: '',
                allowHTML: true,
                removeItems: true,
                removeItemButton: true,
                editItems: true,
                noChoicesText: 'Brak opcji',
                classNames: {
                    containerOuter: 'choices form-select',
                },
            });

            this.selectsInstances.push(selectInstance);
        });
    }

    setPagination() {
        if (!this.pagination) return;
        this.paginationInstance = new Pagination(this.pagination, this.root);
        this.root.addEventListener('pagination-change', e =>
            this.onPaginationChange(e)
        );
    }

    setClearButton() {
        if (!this.clearEl) return;

        this.onClear = this.onClearClick.bind(this);
        this.clearEl.addEventListener('click', this.onClear);
    }

    setAvSelect() {
        if (!this.avSelectEl) return;

        this.onAvChange = this.onAvSelectChange.bind(this);
        this.avSelectEl.addEventListener('change', this.onAvChange);
    }

    setListView() {
        if (!this.listViewEl) return;

        this.listViewEl.forEach(item => {
            item.addEventListener('click', this.loadPremises.bind(this));
        });
    }

    set3dModel() {
        this.load3destate();
    }

    load3destate(appId) {
        const id = this.model3dId;

        const script = document.createElement('script');
        script.src =
            'https://oneappappsprd.blob.core.windows.net/$web/launcher/staging/app.js';
        script.type = 'text/javascript';
        script.onload = function () {
            window.AppLauncher3DEOA.init({
                appId: id,
                rootElement: 'smart-makieta',
                props: {
                    onUnitOpen: unit => {
                        console.log(unit);
                    },
                    unitFiltersInit: [],
                },
            }).then(appInstance => {
                const event = new CustomEvent('3destateloaded', {
                    detail: appInstance,
                });
                window.dispatchEvent(event);
            });
        }.bind(this);
        document.head.append(script);
    }

    onMoreButtonClick() {
        if (!this.moreContent) return;

        const currClasses = Array.from(this.moreContent.classList);
        const buttonExpand = this.moreButton.getAttribute(
            'investments-filters-more-expand'
        );
        const buttonCollapse = this.moreButton.getAttribute(
            'investments-filters-more-collapse'
        );

        let buttonText = buttonExpand;

        if (!currClasses.includes('d-none')) {
            this.moreContent.classList.add('d-none');
            this.moreContent.classList.remove('d-flex');
        } else {
            buttonText = buttonCollapse;
            this.moreContent.classList.remove('d-none');
            this.moreContent.classList.add('d-flex');
        }

        this.moreButton.innerHTML = buttonText;
    }

    async onPaginationChange(event) {
        const { detail } = event;
        this.currentPage = detail;

        await this.loadPremises();
    }

    onClearClick() {
        if (!this.formEl) return;
        const inputs = this.formEl.querySelectorAll(`input:not([type=hidden])`);

        if (inputs) {
            inputs.forEach(item => {
                item.value = '';
                item.checked = '';
            });
        }

        if (this.selectsInstances) {
            this.selectsInstances.forEach(item => {
                item.destroy();
                item.init();
            });
        }

        if (this.rangesInstances) {
            this.rangesInstances.forEach(item => {
                item.enable();
            });
        }
        this.loadPremises();
    }

    onAvSelectChange(event) {
        const { currentTarget } = event;
        const { value } = currentTarget;

        const avRentableEl = this.root.querySelector(
            'input[name="av_rentable_term"]'
        );
        const priceRangeInstance = this.rangesInstances.find(
            item => item.id === 'price-range'
        );
        const rentableID = avRentableEl ? avRentableEl.value : null;

        if (!rentableID) return;
        if (parseInt(value) === parseInt(rentableID)) {
            if (priceRangeInstance) {
                priceRangeInstance.disable();
            }
        } else {
            if (priceRangeInstance) {
                priceRangeInstance.enable();
            }
        }
    }

    async loadPremises() {
        const formData = new FormData(this.formEl);
        if (this.currentPage) formData.append('page', this.currentPage);

        if (this.loaderEl) this.loaderEl.classList.remove('-hide');

        this.setURLParams(formData);

        if (!this.redirectUrlEl) {
            this.setURLParams(formData);
            this.setView(formData);

            const request = await fetch(
                `${home_url}/wp-json/premises/v1/list`,
                {
                    method: 'POST',
                    body: formData,
                }
            );

            const res = await request.json();
            const { html, max_pages, crm_ids } = res;

            if (this.listEl) {
                this.listEl.innerHTML = html;
            }

            this.maxPages = max_pages;
            this.paginationInstance.reset(this.currentPage, this.maxPages);

            if (crm_ids.length > 0 && this.model3dEstate) {
                this.model3dEstate.unitFiltersSet([
                    {
                        filterType: 'match',
                        unitValue: { $g: 'customCrmId' },
                        matches: [
                            {
                                values: crm_ids,
                            },
                        ],
                    },
                ]);
            }

            if (this.loaderEl) this.loaderEl.classList.add('-hide');
        } else {
            this.redirectURLParams(formData);
        }
    }

    setView(formData) {
        const view = formData.get('list_view');

        if (view === '3d') {
            if (this.model3dEl) this.model3dEl.classList.remove('-hide');
            this.listEl.classList.add('-hide');

            if (this.paginationEl) this.paginationEl.classList.add('-hide');
        } else {
            if (this.model3dEl) this.model3dEl.classList.add('-hide');
            this.listEl.classList.remove('-hide');

            if (this.paginationEl) this.paginationEl.classList.remove('-hide');
        }
    }

    setURLParams(formData) {
        const params = new URLSearchParams();

        for (let [key, value] of formData.entries()) {
            if (key !== 'site_url') params.append(key, value);
        }

        const urlWithParams = `${this.siteURL}?${params.toString()}`;
        history.replaceState(null, '', urlWithParams);
    }

    redirectURLParams(formData) {
        const params = new URLSearchParams();
        const redirectUrlStr = this.redirectUrlEl.value;

        for (let [key, value] of formData.entries()) {
            if (key !== 'site_url') params.append(key, value);
        }

        const urlWithParams = `${redirectUrlStr}?${params.toString()}`;
        console.log(urlWithParams);

        window.location.assign(urlWithParams);
    }
}

function initInvestmentsFilters() {
    const sections = document.querySelectorAll('[investments-filters]');
    if (!sections) return;

    sections.forEach(item => new InvestmentsFilters(item));
}

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    initInvestmentsFilters();
} else {
    document.addEventListener('DOMContentLoaded', initInvestmentsFilters);
}
